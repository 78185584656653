.tab {
    margin-left: 15px;
}

.spaced {
    margin-top: 15px;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.text-smaller {
    font-size: smaller;
}

.h-full-page {
    height: 93vh
}

.text-smaller {
    font-size: smaller;
}

.z-1 {
    z-index: 1 !important;
    position: relative !important;
}

.pr-6,
.px-6 {
    padding-right: 6rem !important;
}

.pl-6,
.px-6 {
    padding-left: 6rem !important;
}

.pt-6,
.py-6 {
    padding-top: 6rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 6rem !important;
}

.pt-8,
.py-8 {
    padding-top: 8rem !important;
}

.pb-8,
.py-8 {
    padding-bottom: 8rem !important;
}

.pt-10,
.py-10 {
    padding-top: 10rem !important;
}

.pb-10,
.py-10 {
    padding-bottom: 10rem !important;
}

.lift {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
    -webkit-transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

.lift:hover {
    -webkit-transform: translateY(-0.3333333333rem);
    transform: translateY(-0.3333333333rem);
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25);
}

.lift:active {
    -webkit-transform: none;
    transform: none;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.lift-sm {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.lift-sm:hover {
    -webkit-transform: translateY(-0.1666666667rem);
    transform: translateY(-0.1666666667rem);
    box-shadow: 0 0.25rem 1rem 0 rgba(31, 45, 65, 0.25);
}

.lift-sm:active {
    -webkit-transform: none;
    transform: none;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.card.lift {
    text-decoration: none;
    color: inherit;
}

.text-uppercase-expanded,
.navbar-marketing .btn,
.navbar .dropdown-menu .dropdown-header {
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.text-gray-400 {
    color: #c5ccd6 !important;
}

.text-gray-500 {
    color: #a2acba !important;
}

.text-gray-600 {
    color: #687281 !important;
}

.text-gray-700 {
    color: #485260 !important;
}

.text-gray-800 {
    color: #323f52 !important;
}

.rounded-sm {
    border-radius: 0.25rem !important;
}

.rounded {
    border-radius: 0.35rem !important;
}

.rounded-top {
    border-top-left-radius: 0.35rem !important;
    border-top-right-radius: 0.35rem !important;
}

.rounded-right {
    border-top-right-radius: 0.35rem !important;
    border-bottom-right-radius: 0.35rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.35rem !important;
    border-bottom-left-radius: 0.35rem !important;
}

.rounded-left {
    border-top-left-radius: 0.35rem !important;
    border-bottom-left-radius: 0.35rem !important;
}

.rounded-lg {
    border-radius: 0.5rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.content-skewed {
    -webkit-perspective: 1500px;
    perspective: 1500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.content-skewed-right {
    -webkit-perspective-origin: right center;
    perspective-origin: right center;
}

.content-skewed-right .content-skewed-item {
    -webkit-transform: rotateY(30deg) rotateX(15deg);
    transform: rotateY(30deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.content-skewed-left {
    -webkit-perspective-origin: left center;
    perspective-origin: left center;
}

.content-skewed-left .content-skewed-item {
    -webkit-transform: rotateY(-30deg) rotateX(15deg);
    transform: rotateY(-30deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.object-wrap {
	position: relative;
	overflow: hidden;
}

.object-body {
	height: 35vw;
	min-height: 250px;
}

@media (min-width: 992px) {
	.object-body {
		position: absolute;
		height: auto;
		width: 50%;
		top: 0;
		bottom: 0;
	}
}

@media (min-width: 992px) {
	.object-75 {
		width: calc((100% - 992px / 100 * 50 ) /2);
	}
}

@media (min-width: 1200px) {
	.object-75 {
		width: calc((100% - 1200px / 100 * 50 ) /2);
	}
}

.object-body-left {
	left: 0;
}

.object-body-right {
	right: 0;
}

.object-inline {
	white-space: nowrap;
}

.object-inline > * {
	display: inline-block;
	min-width: 20px;
	vertical-align: top;
	white-space: normal;
}

.object-inline > * + * {
	margin-left: 5px;
}