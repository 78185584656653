.pricing {
    font-size: 0.9rem;
    color: var(--text-colour);
}

.pricing .pricing-price {
    font-size: 3rem;
    color: #323f52;
    margin-bottom: 1rem;
    color: var(--text-colour);
}

.pricing .pricing-price sup {
    font-size: 1.5rem;
}

.pricing .pricing-price .pricing-price-period {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--text-colour);
}

.pricing .pricing-list {
    margin-bottom: 0;
    margin-left: 1.6em;
}

.pricing .pricing-list .pricing-list-item {
    margin-bottom: 0.5rem;
    color: var(--text-colour);
}

@media (min-width: 992px) {
    .pricing-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pricing-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.svg-white {
    color: var(--background-color) !important;
}

.svg-light {
    color: var(--bg-light) !important;
}

.svg-dark {
    color: var(--bg-dark) !important;
}

.value-prop-title {
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
}

.value-prop-subtitle {
    color: var(--secondary);
    font-weight: 500;
}

.value-prop-text {
    margin-top: 50px;
}

.bg-about {
    background: 
    linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(20, 20, 20, 0.73)),
    url("../../assets/img/headers/bg_2.jpg") center center no-repeat;
}

.about-title {
    text-transform: uppercase;
    font-weight: 600;
}

.cta-title {
    text-transform: uppercase;
    font-weight: 600;
}

.bg-results {
    background: url("../../assets/img/headers/bg-results.jpg") center center no-repeat;
    background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.bg-services {
    background: url("../../assets/img/headers/bg-services.jpg") center center no-repeat;
    background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

iframe {
    display: block;
    background: #000;
    border: none;
    height: calc(100vh - 30px);
    width: 100%;
}