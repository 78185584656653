footer {
  display: block;
}

.footer .footer-brand {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.footer .footer-brand svg {
  height: 1rem;
}

.footer.footer-dark {
  color: rgba(255, 255, 255, 0.6);
}

.footer.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}

.footer.footer-light {
  color: #a2acba;
}