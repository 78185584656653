@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600&display=swap);
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-family: 'Josefin Sans', sans-serif;
    margin-right: -7rem !important;
    font-size: 2rem;
    color: var(--text-colour) !important;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.content {
    width: 94%;
    margin: 4em auto;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

.logo {
    line-height: 60px;
    position: fixed;
    float: left;
    margin: 16px 46px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
}

nav {
    background-color: var(--bg-dark) !important;
    position: fixed;
    width: 100%;
    line-height: 60px;
}

nav ul {
    line-height: 60px;
    list-style: none;
    background: rgba(0, 0, 0, 0);
    overflow: visible;
    padding: 0;
    text-align: right;
    margin: 0;
    padding-right: 40px;
    transition: 1s;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
}

.dropdown-item {
    line-height: 30px !important;
}

.dropdown-item:active {
    background-color: var(--primary);
}

nav.black ul {
    background: #000;
}

nav ul li {
    display: inline-block;
}

nav ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.nav-link {
    color: var(--white) !important;
    background-color: transparent !important;
}

.nav-link:hover {
    color: rgb(120, 119, 119) !important;
}

.dropdown-menu {
    background-color: var(--background-color-secondary);
}

.dropdown-item:hover {
    background-color: var(--background-color-secondary);
}

.navbar-toggler-icon {
    color: var(--text-colour) !important;
}

@media (prefers-color-scheme: dark) {
    nav {
        background-color: var(--background-color-secondary);
    }
}
footer {
  display: block;
}

.footer .footer-brand {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.footer .footer-brand svg {
  height: 1rem;
}

.footer.footer-dark {
  color: rgba(255, 255, 255, 0.6);
}

.footer.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}

.footer.footer-light {
  color: #a2acba;
}
.alert {
    border-radius: 10px;
    position: fixed;
    top: 100px;
    right: 0px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: fit-content;
    z-index: 1111;
}

.toast {
    width: 250px;
}

.toast-header {
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    font-weight: bold;
    font-family: "proxima_nova", Helvetica, Arial, sans-serif!important;
    border: 0;
}

.success-border {
    border-left-width: 7px;
    border-left-color: #27ae60;
}

.danger-border {
    border-left-width: 7px;
    border-left-color: #e74c3c;
}

.toast-body {
    min-height: 10vh;
    background-color: rgba(255, 255, 255, 0.5);
}
.pricing {
    font-size: 0.9rem;
    color: var(--text-colour);
}

.pricing .pricing-price {
    font-size: 3rem;
    color: #323f52;
    margin-bottom: 1rem;
    color: var(--text-colour);
}

.pricing .pricing-price sup {
    font-size: 1.5rem;
}

.pricing .pricing-price .pricing-price-period {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--text-colour);
}

.pricing .pricing-list {
    margin-bottom: 0;
    margin-left: 1.6em;
}

.pricing .pricing-list .pricing-list-item {
    margin-bottom: 0.5rem;
    color: var(--text-colour);
}

@media (min-width: 992px) {
    .pricing-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pricing-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.svg-white {
    color: var(--background-color) !important;
}

.svg-light {
    color: var(--bg-light) !important;
}

.svg-dark {
    color: var(--bg-dark) !important;
}

.value-prop-title {
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
}

.value-prop-subtitle {
    color: var(--secondary);
    font-weight: 500;
}

.value-prop-text {
    margin-top: 50px;
}

.bg-about {
    background: 
    linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(20, 20, 20, 0.73)),
    url(/static/media/bg_2.448293dd.jpg) center center no-repeat;
}

.about-title {
    text-transform: uppercase;
    font-weight: 600;
}

.cta-title {
    text-transform: uppercase;
    font-weight: 600;
}

.bg-results {
    background: url(/static/media/bg-results.8213d65f.jpg) center center no-repeat;
    background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.bg-services {
    background: url(/static/media/bg-services.2cb0c170.jpg) center center no-repeat;
    background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

iframe {
    display: block;
    background: #000;
    border: none;
    height: calc(100vh - 30px);
    width: 100%;
}


.tab {
    margin-left: 15px;
}

.spaced {
    margin-top: 15px;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.text-smaller {
    font-size: smaller;
}

.h-full-page {
    height: 93vh
}

.text-smaller {
    font-size: smaller;
}

.z-1 {
    z-index: 1 !important;
    position: relative !important;
}

.pr-6,
.px-6 {
    padding-right: 6rem !important;
}

.pl-6,
.px-6 {
    padding-left: 6rem !important;
}

.pt-6,
.py-6 {
    padding-top: 6rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 6rem !important;
}

.pt-8,
.py-8 {
    padding-top: 8rem !important;
}

.pb-8,
.py-8 {
    padding-bottom: 8rem !important;
}

.pt-10,
.py-10 {
    padding-top: 10rem !important;
}

.pb-10,
.py-10 {
    padding-bottom: 10rem !important;
}

.lift {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lift:hover {
    transform: translateY(-0.3333333333rem);
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25);
}

.lift:active {
    transform: none;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.lift-sm {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.lift-sm:hover {
    transform: translateY(-0.1666666667rem);
    box-shadow: 0 0.25rem 1rem 0 rgba(31, 45, 65, 0.25);
}

.lift-sm:active {
    transform: none;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.card.lift {
    text-decoration: none;
    color: inherit;
}

.text-uppercase-expanded,
.navbar-marketing .btn,
.navbar .dropdown-menu .dropdown-header {
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.text-gray-400 {
    color: #c5ccd6 !important;
}

.text-gray-500 {
    color: #a2acba !important;
}

.text-gray-600 {
    color: #687281 !important;
}

.text-gray-700 {
    color: #485260 !important;
}

.text-gray-800 {
    color: #323f52 !important;
}

.rounded-sm {
    border-radius: 0.25rem !important;
}

.rounded {
    border-radius: 0.35rem !important;
}

.rounded-top {
    border-top-left-radius: 0.35rem !important;
    border-top-right-radius: 0.35rem !important;
}

.rounded-right {
    border-top-right-radius: 0.35rem !important;
    border-bottom-right-radius: 0.35rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.35rem !important;
    border-bottom-left-radius: 0.35rem !important;
}

.rounded-left {
    border-top-left-radius: 0.35rem !important;
    border-bottom-left-radius: 0.35rem !important;
}

.rounded-lg {
    border-radius: 0.5rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.content-skewed {
    perspective: 1500px;
    transform-style: preserve-3d;
}

.content-skewed-right {
    perspective-origin: right center;
}

.content-skewed-right .content-skewed-item {
    transform: rotateY(30deg) rotateX(15deg);
    backface-visibility: hidden;
}

.content-skewed-left {
    perspective-origin: left center;
}

.content-skewed-left .content-skewed-item {
    transform: rotateY(-30deg) rotateX(15deg);
    backface-visibility: hidden;
}

.object-wrap {
	position: relative;
	overflow: hidden;
}

.object-body {
	height: 35vw;
	min-height: 250px;
}

@media (min-width: 992px) {
	.object-body {
		position: absolute;
		height: auto;
		width: 50%;
		top: 0;
		bottom: 0;
	}
}

@media (min-width: 992px) {
	.object-75 {
		width: calc((100% - 992px / 100 * 50 ) /2);
	}
}

@media (min-width: 1200px) {
	.object-75 {
		width: calc((100% - 1200px / 100 * 50 ) /2);
	}
}

.object-body-left {
	left: 0;
}

.object-body-right {
	right: 0;
}

.object-inline {
	white-space: nowrap;
}

.object-inline > * {
	display: inline-block;
	min-width: 20px;
	vertical-align: top;
	white-space: normal;
}

.object-inline > * + * {
	margin-left: 5px;
}
.form-control {
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border: 2px solid rgba(238, 237, 237, 0.8);
    font-size: 13px !important;
    font-weight: 300;
    border-radius: 0px;
}

.form-control:focus,
.form-control:active {
    box-shadow: none;
    border: 2px solid var(--primary);
}

input[type="password"],
input[type="email"],
input[type="text"] {
    height: 50px;
}

select.form-control {
    height: 50px;
}

.form-group {
    margin-bottom: 30px;
}

.form-group .btn-modify {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 13px;
    padding: 10px 15px;
}

label {
    font-size: small;
    padding-top: 10px;
}

a {
    color: var(--primary);
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: var(--primary-dark);
    outline: none;
    text-decoration: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-teal:hover {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-teal:focus,
.btn-teal.focus {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
    box-shadow: 0 0 0 0.2rem rgba(196, 138, 38, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #875500;
    border-color: #875500;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #875500;
    border-color: #875500;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 138, 38, 0.5);
}
.bg-gradient-primary-to-secondary {
    background: 
    linear-gradient(to bottom, rgba(153, 154, 155, 0.52), rgba(20, 20, 20, 0.73)),
    url(/static/media/hero-bg.fce8d2a0.jpg) center center no-repeat;
    background-size: cover;
}

.img-header-1 {
    background-image: url(/static/media/bg-hero-light.94a1ef1b.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-2 {
    background-image: url(/static/media/bg-hero-t2.9a994e63.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-3 {
    background-image: url(/static/media/bg-hero-3.7c1ce165.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-4 {
    background-image: url(/static/media/bg-login-background.fd8c7a84.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-5 {
    background-image: url(/static/media/bg-hero-5.03741621.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-6 {
    background-image: url(/static/media/bg-hero-6.7c1ce165.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-7 {
    background-image: url(/static/media/bg-hero-7.5bede911.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}
:root {
    --blue: #e76712;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --white: #fff;
    --gray: #687281;
    --gray-dark: #323f52;
    --success: #00ac69;
    --info: #00cfd5;
    --warning: #f4a100;
    --danger: #e81500;
    --light: #eff3f9;
    --dark: #1f2d41;
    --black: #000;
    --white: #fff;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --blue: #0061f2;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red-soft: #eec7c7;
    --orange-soft: #f1d6c7;
    --yellow-soft: #f0e3c7;
    --green-soft: #bfe5dc;
    --teal-soft: #bfe8e5;
    --cyan-soft: #bfecf2;
    --blue-soft: #bfd6f8;
    --indigo-soft: #d1c2f6;
    --purple-soft: #d4c2ef;
    --pink-soft: #edc2d9;
    --primary-soft: #bfd6f8;
    --secondary-soft: #d4c2ef;
    --success-soft: #bfe5dc;
    --info-soft: #bfecf2;
    --warning-soft: #f0e3c7;
    --danger-soft: #eec7c7;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bg-light: #F8F9FA;
    --bg-dark: #393939;
    --background-color: #ffffff;
    --background-color-secondary: #F8F7F7;
    --background-color-tertiary: #ffffff;
    --text-colour: #2b2b2b;
    --primary: #00ADEF;
    --secondary: #12294B;
    --primary-dark: #a9a2a5;
}

@media (prefers-color-scheme: dark) {
     :root {
        --background-color: #303030;
        --background-color-secondary: #434343;
        --dark: #2d2c2c;
        --white: rgb(235, 235, 235);
        --text-colour: var(--white);
        --bg-light: #5b5b5b;
        --bg-dark: #393939;
    }
    p,
    td,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .lead {
        color: var(--white);
    }
    .card,
    .modal-header,
    .modal-body {
        background-color: var(--background-color-secondary);
        ;
    }
    .btn {
        color: var(--white)
    }
    .form-control,
    .form-control:focus {
        background-color: var(--bg-dark);
        color: var(--white);
    }
    .form-control::placeholder {
        color: #a9a9a9;
        opacity: 1
    }
    .bg-white {
        background-color: var(--background-color) !important;
    }
    .bg-light {
        background-color: var(--bg-light) !important;
    }
    .bg-dark {
        background-color: var(--bg-dark) !important;
    }
    .bg-secondary {
        background-color: #3c3c3c !important;
    }
    nav {
        background-color: var(--background-color-secondary) !important;
    }
}

.page-content {
    background-color: var(--background-color);
}
h1,
h2,
h3,
h4,
h5,
h6,
figure,
.lead {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
}

a,
button {
    font-family: 'Poppins', sans-serif;
}

.form-control {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.page-header {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.page-header .page-header-content .page-header-title {
    font-size: 3.8rem;
    font-family: 'Mukta', sans-serif;
    font-weight: 700;
}

.page-header .page-header-content .page-header-text {
    font-size: 1.15rem;
    font-family: 'Mukta', sans-serif;
}

.page-header .page-header-content .page-header-text.small {
    font-size: 0.9rem;
}

.page-header .page-header-content .page-header-signup {
    justify-content: center;
}

.page-header .page-header-content .page-header-signup .form-control {
    font-size: 0.875em;
    padding: 0 1.5rem;
    height: 2.725rem;
    line-height: 1.5;
}

.page-header-dark {
    color: #fff;
    background-color: #1f2d41;
}

.page-header-dark .page-header-title {
    color: #fff;
    text-transform: uppercase;
}

.page-header-light {
    background-color: #eff3f9;
}

.page-header-light .page-header-text {
    color: #687281;
}

.page-header-light .page-header-text a {
    color: #485260;
}

.page-header.navbar-fixed .page-header-content {
    padding-top: 12.5rem;
}

.svg-border-angled {
    padding-top: 5rem;
    color: var(--background-color);
}

.svg-border-angled svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    color: var(--background-color);
}

.svg-border-rounded svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    color: var(--background-color);
}

.svg-border-rounded-no-absolute svg {
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}

.icon-stack {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
    background-color: #eff3f9;
}

.icon-stack svg {
    height: 1rem;
    width: 1rem;
}

.icon-stack-lg {
    height: 4rem;
    width: 4rem;
    font-size: 1.5rem;
}

.icon-stack-lg svg {
    height: 1.5rem;
    width: 1.5rem;
}

.icon-stack-xl {
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
}

.icon-stack-xl svg {
    height: 1.75rem;
    width: 1.75rem;
}

.icon-list-social {
    display: flex;
}

.icon-list-social .icon-list-social-link {
    font-size: 1.25rem;
    margin-right: 0.5rem;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.testimonial .testimonial-brand {
    fill: currentColor;
    margin-bottom: 1.35rem;
    color: var(--primary);
}

.testimonial .testimonial-brand svg {
    height: 3rem;
}

.testimonial .testimonial-quote {
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--primary);
}

.testimonial .testimonial-name {
    font-weight: bold;
    color: var(--text-colour);
}

.testimonial .testimonial-position {
    font-weight: lighter;
    color: var(--text-colour);
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.35rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #0061f2;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #004dbf;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #6900c7;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #4e0094;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #00ac69;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #00794a;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #00cfd5;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #009da2;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 213, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4a100;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #fff;
    background-color: #c17f00;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #e81500;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #b51000;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.5);
}

.badge-light {
    color: #1f2d41;
    background-color: #eff3f9;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #1f2d41;
    background-color: #cad7eb;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 249, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #1f2d41;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #0f151e;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.5);
}

.badge-primary-soft {
    color: #1f2d41 !important;
    background-color: #bfd6f8;
}

a.badge-primary-soft:hover,
a.badge-primary-soft:focus {
    color: #1f2d41;
    background-color: #91b9f3;
}

a.badge-primary-soft:focus,
a.badge-primary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.badge-secondary-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
}

a.badge-secondary-soft:hover,
a.badge-secondary-soft:focus {
    color: #1f2d41;
    background-color: #b89ae4;
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.text-primary {
    color: var(--primary) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-dark) !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: var(--primary-dark) !important;
}

.bg-secondary {
    background-color: #4d4d4d !important;
}
