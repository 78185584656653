@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap');
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-family: 'Josefin Sans', sans-serif;
    margin-right: -7rem !important;
    font-size: 2rem;
    color: var(--text-colour) !important;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.content {
    width: 94%;
    margin: 4em auto;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

.logo {
    line-height: 60px;
    position: fixed;
    float: left;
    margin: 16px 46px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
}

nav {
    background-color: var(--bg-dark) !important;
    position: fixed;
    width: 100%;
    line-height: 60px;
}

nav ul {
    line-height: 60px;
    list-style: none;
    background: rgba(0, 0, 0, 0);
    overflow: visible;
    padding: 0;
    text-align: right;
    margin: 0;
    padding-right: 40px;
    transition: 1s;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
}

.dropdown-item {
    line-height: 30px !important;
}

.dropdown-item:active {
    background-color: var(--primary);
}

nav.black ul {
    background: #000;
}

nav ul li {
    display: inline-block;
}

nav ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.nav-link {
    color: var(--white) !important;
    background-color: transparent !important;
}

.nav-link:hover {
    color: rgb(120, 119, 119) !important;
}

.dropdown-menu {
    background-color: var(--background-color-secondary);
}

.dropdown-item:hover {
    background-color: var(--background-color-secondary);
}

.navbar-toggler-icon {
    color: var(--text-colour) !important;
}

@media (prefers-color-scheme: dark) {
    nav {
        background-color: var(--background-color-secondary);
    }
}