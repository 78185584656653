.form-control {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border: 2px solid rgba(238, 237, 237, 0.8);
    font-size: 13px !important;
    font-weight: 300;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

.form-control:focus,
.form-control:active {
    box-shadow: none;
    border: 2px solid var(--primary);
}

input[type="password"],
input[type="email"],
input[type="text"] {
    height: 50px;
}

select.form-control {
    height: 50px;
}

.form-group {
    margin-bottom: 30px;
}

.form-group .btn-modify {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 13px;
    padding: 10px 15px;
}

label {
    font-size: small;
    padding-top: 10px;
}

a {
    color: var(--primary);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: var(--primary-dark);
    outline: none;
    text-decoration: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary-dark);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-teal:hover {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-teal:focus,
.btn-teal.focus {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
    box-shadow: 0 0 0 0.2rem rgba(196, 138, 38, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #875500;
    border-color: #875500;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #875500;
    border-color: #875500;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 138, 38, 0.5);
}