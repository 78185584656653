.alert {
    border-radius: 10px;
    position: fixed;
    top: 100px;
    right: 0px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: fit-content;
    z-index: 1111;
}

.toast {
    width: 250px;
}

.toast-header {
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    font-weight: bold;
    font-family: "proxima_nova", Helvetica, Arial, sans-serif!important;
    border: 0;
}

.success-border {
    border-left-width: 7px;
    border-left-color: #27ae60;
}

.danger-border {
    border-left-width: 7px;
    border-left-color: #e74c3c;
}

.toast-body {
    min-height: 10vh;
    background-color: rgba(255, 255, 255, 0.5);
}