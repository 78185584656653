.bg-gradient-primary-to-secondary {
    background: 
    linear-gradient(to bottom, rgba(153, 154, 155, 0.52), rgba(20, 20, 20, 0.73)),
    url("../img/hero/hero-bg.jpg") center center no-repeat;
    background-size: cover;
}

.img-header-1 {
    background-image: url(../img/headers/bg-hero-light.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-2 {
    background-image: url(../img/headers/bg-hero-t2.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-3 {
    background-image: url(../img/headers/bg-hero-3.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-4 {
    background-image: url(../img/headers/bg-login-background.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-5 {
    background-image: url(../img/headers/bg-hero-5.jpg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-6 {
    background-image: url(../img/headers/bg-hero-6.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}

.img-header-7 {
    background-image: url(../img/headers/bg-hero-7.svg);
    background-position: right -40px;
    background-repeat: no-repeat;
    background-size: cover
}