:root {
    --blue: #e76712;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --white: #fff;
    --gray: #687281;
    --gray-dark: #323f52;
    --success: #00ac69;
    --info: #00cfd5;
    --warning: #f4a100;
    --danger: #e81500;
    --light: #eff3f9;
    --dark: #1f2d41;
    --black: #000;
    --white: #fff;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --blue: #0061f2;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red-soft: #eec7c7;
    --orange-soft: #f1d6c7;
    --yellow-soft: #f0e3c7;
    --green-soft: #bfe5dc;
    --teal-soft: #bfe8e5;
    --cyan-soft: #bfecf2;
    --blue-soft: #bfd6f8;
    --indigo-soft: #d1c2f6;
    --purple-soft: #d4c2ef;
    --pink-soft: #edc2d9;
    --primary-soft: #bfd6f8;
    --secondary-soft: #d4c2ef;
    --success-soft: #bfe5dc;
    --info-soft: #bfecf2;
    --warning-soft: #f0e3c7;
    --danger-soft: #eec7c7;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bg-light: #F8F9FA;
    --bg-dark: #393939;
    --background-color: #ffffff;
    --background-color-secondary: #F8F7F7;
    --background-color-tertiary: #ffffff;
    --text-colour: #2b2b2b;
    --primary: #00ADEF;
    --secondary: #12294B;
    --primary-dark: #a9a2a5;
}

@media (prefers-color-scheme: dark) {
     :root {
        --background-color: #303030;
        --background-color-secondary: #434343;
        --dark: #2d2c2c;
        --white: rgb(235, 235, 235);
        --text-colour: var(--white);
        --bg-light: #5b5b5b;
        --bg-dark: #393939;
    }
    p,
    td,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .lead {
        color: var(--white);
    }
    .card,
    .modal-header,
    .modal-body {
        background-color: var(--background-color-secondary);
        ;
    }
    .btn {
        color: var(--white)
    }
    .form-control,
    .form-control:focus {
        background-color: var(--bg-dark);
        color: var(--white);
    }
    .form-control::placeholder {
        color: #a9a9a9;
        opacity: 1
    }
    .bg-white {
        background-color: var(--background-color) !important;
    }
    .bg-light {
        background-color: var(--bg-light) !important;
    }
    .bg-dark {
        background-color: var(--bg-dark) !important;
    }
    .bg-secondary {
        background-color: #3c3c3c !important;
    }
    nav {
        background-color: var(--background-color-secondary) !important;
    }
}

.page-content {
    background-color: var(--background-color);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600&display=swap');
h1,
h2,
h3,
h4,
h5,
h6,
figure,
.lead {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
}

a,
button {
    font-family: 'Poppins', sans-serif;
}

.form-control {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.page-header {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.page-header .page-header-content .page-header-title {
    font-size: 3.8rem;
    font-family: 'Mukta', sans-serif;
    font-weight: 700;
}

.page-header .page-header-content .page-header-text {
    font-size: 1.15rem;
    font-family: 'Mukta', sans-serif;
}

.page-header .page-header-content .page-header-text.small {
    font-size: 0.9rem;
}

.page-header .page-header-content .page-header-signup {
    -webkit-box-pack: center;
    justify-content: center;
}

.page-header .page-header-content .page-header-signup .form-control {
    font-size: 0.875em;
    padding: 0 1.5rem;
    height: 2.725rem;
    line-height: 1.5;
}

.page-header-dark {
    color: #fff;
    background-color: #1f2d41;
}

.page-header-dark .page-header-title {
    color: #fff;
    text-transform: uppercase;
}

.page-header-light {
    background-color: #eff3f9;
}

.page-header-light .page-header-text {
    color: #687281;
}

.page-header-light .page-header-text a {
    color: #485260;
}

.page-header.navbar-fixed .page-header-content {
    padding-top: 12.5rem;
}

.svg-border-angled {
    padding-top: 5rem;
    color: var(--background-color);
}

.svg-border-angled svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    color: var(--background-color);
}

.svg-border-rounded svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    color: var(--background-color);
}

.svg-border-rounded-no-absolute svg {
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}

.icon-stack {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
    background-color: #eff3f9;
}

.icon-stack svg {
    height: 1rem;
    width: 1rem;
}

.icon-stack-lg {
    height: 4rem;
    width: 4rem;
    font-size: 1.5rem;
}

.icon-stack-lg svg {
    height: 1.5rem;
    width: 1.5rem;
}

.icon-stack-xl {
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
}

.icon-stack-xl svg {
    height: 1.75rem;
    width: 1.75rem;
}

.icon-list-social {
    display: -webkit-box;
    display: flex;
}

.icon-list-social .icon-list-social-link {
    font-size: 1.25rem;
    margin-right: 0.5rem;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.testimonial .testimonial-brand {
    fill: currentColor;
    margin-bottom: 1.35rem;
    color: var(--primary);
}

.testimonial .testimonial-brand svg {
    height: 3rem;
}

.testimonial .testimonial-quote {
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--primary);
}

.testimonial .testimonial-name {
    font-weight: bold;
    color: var(--text-colour);
}

.testimonial .testimonial-position {
    font-weight: lighter;
    color: var(--text-colour);
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        -webkit-transition: none;
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #0061f2;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #004dbf;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #6900c7;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #4e0094;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #00ac69;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #00794a;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #00cfd5;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #009da2;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 213, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4a100;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #fff;
    background-color: #c17f00;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #e81500;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #b51000;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.5);
}

.badge-light {
    color: #1f2d41;
    background-color: #eff3f9;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #1f2d41;
    background-color: #cad7eb;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 249, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #1f2d41;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #0f151e;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.5);
}

.badge-primary-soft {
    color: #1f2d41 !important;
    background-color: #bfd6f8;
}

a.badge-primary-soft:hover,
a.badge-primary-soft:focus {
    color: #1f2d41;
    background-color: #91b9f3;
}

a.badge-primary-soft:focus,
a.badge-primary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.badge-secondary-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
}

a.badge-secondary-soft:hover,
a.badge-secondary-soft:focus {
    color: #1f2d41;
    background-color: #b89ae4;
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.text-primary {
    color: var(--primary) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-dark) !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: var(--primary-dark) !important;
}

.bg-secondary {
    background-color: #4d4d4d !important;
}